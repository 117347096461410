import React from 'react';
import { Link } from 'react-router-dom';

function BlogPage() {
  // This would typically come from an API or CMS
  const blogPosts = [
    {
      id: 1,
      title: 'Understanding GDPR Compliance',
      summary: 'An overview of GDPR requirements and how to ensure compliance.',
      image: '/images/blog-post-1.jpg',
      date: '2023-05-15',
    },
    {
      id: 2,
      title: 'Data Protection Best Practices',
      summary: 'Key strategies to protect user data and maintain privacy.',
      image: '/images/blog-post-2.jpg',
      date: '2023-05-10',
    },
    {
      id: 3,
      title: 'The Future of Data Privacy',
      summary:
        'Emerging trends and technologies shaping data privacy landscape.',
      image: '/images/blog-post-3.jpg',
      date: '2023-05-05',
    },
  ];

  return (
    <div className='section'>
      <div className='container-12 w-container'>
        <h2 className='heading-2'>Blogbeiträge zum Datenschutz</h2>
        <div className='w-dyn-list'>
          <div role='list' className='collection-list w-dyn-items w-row'>
            {blogPosts.map((post) => (
              <div
                key={post.id}
                role='listitem'
                className='collection-item-3 w-dyn-item w-col w-col-4'
              >
                <Link
                  to={`/blog/${post.id}`}
                  className='link-block-2 w-inline-block'
                >
                  <img src={post.image} alt={post.title} className='image-8' />
                  <h3 className='heading-19'>{post.title}</h3>
                  <div className='text-block-3'>{post.summary}</div>
                  <div className='blog-summary-subline'>{post.date}</div>
                </Link>
              </div>
            ))}
          </div>
          {blogPosts.length === 0 && (
            <div className='w-dyn-empty'>
              <div>No items found.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BlogPage;
