import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Komponenten importieren
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import BlogPage from './components/BlogPage';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';

// CSS-Dateien importieren
import './css/normalize.css';
import './css/webflow.css';
import './css/privapi.webflow.css';

// Layout-Komponente erstellen
const Layout = ({ children }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  >
    <Header />
    <main style={{ flex: '1 0 auto' }}>{children}</main>
    <Footer />
  </div>
);

function App() {
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route
            path='/'
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path='/blog'
            element={
              <Layout>
                <BlogPage />
              </Layout>
            }
          />
          <Route
            path='/impressum'
            element={
              <Layout>
                <Impressum />
              </Layout>
            }
          />
          <Route
            path='/datenschutz'
            element={
              <Layout>
                <Datenschutz />
              </Layout>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
