import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/p-logo-saffron_ohne_mittelkreis_512.png';

function Datenschutz() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <header
        data-collapse='medium'
        data-animation='default'
        data-duration='400'
        data-easing='ease'
        data-easing2='ease'
        data-no-scroll='1'
        role='banner'
        className='navigation w-nav'
      >
        <div className='w-container'>
          <Link to='/' className='brand-link w-nav-brand'>
            <img
              src={logo}
              loading='lazy'
              width='50'
              sizes='50px'
              alt=''
              srcSet={`${logo} 500w, ${logo} 512w`}
              className='image-4'
            />
            <div className='logo-text'>
              <strong>PRIVAPI</strong>
            </div>
          </Link>
          <nav role='navigation' className='nav-menu w-nav-menu'>
            <Link to='/#featuresEnterprises' className='nav-link w-nav-link'>
              Für Unternehmen
            </Link>
            <Link to='/#featuresDPO' className='nav-link w-nav-link'>
              Für Datenschutzbeauftragte
            </Link>
            <Link to='/#pricing' className='nav-link w-nav-link'>
              Preise
            </Link>
            <Link to='/blog' className='nav-link w-nav-link'>
              Blog
            </Link>
            <a
              href='https://documentation.privapi.io'
              target='_blank'
              rel='noopener noreferrer'
              className='nav-link w-nav-link'
            >
              Dokumentation
            </a>
            <a href='https://portal.privapi.io' className='nav-link w-nav-link'>
              Login
            </a>
          </nav>
          <div className='nav-link menu w-nav-button'>
            <div className='w-icon-nav-menu'></div>
          </div>
        </div>
      </header>
      <div className='section-2'>
        <div className='container-3 w-container'>
          <h1 className='heading-6'>Datenschutzerklärung</h1>
        </div>
        <div className='container-4 w-container'>
          <div>
            Sie finden die Datenschutzerklärung der PRIVAPI GmbH unter folgendem
            Link:{' '}
            <a
              href='https://privapi-media-dev.s3.eu-central-1.amazonaws.com/legal-documents/privacy/dsg-dse-privapi-io-040422-150217.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              Datenschutz
            </a>
            <br />
            <br />
            <br />
            <br />
            <br />‍<br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Datenschutz;
