import React from 'react';

function ClientsSection() {
  return (
    <div className='section clients'>
      <div className='w-container'>
        <img
          src='images/client_logo_johnson_and_johnson.png'
          width='162'
          alt='Johnson & Johnson logo'
          className='client-logo'
        />
        <img
          src='images/client_logo_tesla.png'
          width='160'
          alt='Tesla logo'
          className='client-logo'
        />
        <img
          src='images/client_logo_ouya.png'
          width='162'
          alt='Ouya logo'
          className='client-logo'
        />
        <img
          src='images/client_logo_chartboost.png'
          width='162'
          alt='Chartboost logo'
          className='client-logo'
        />
        <img
          src='images/client_logo_mammoth.png'
          width='162'
          alt='Mammoth logo'
          className='client-logo'
        />
        <img
          src='images/client_logo_neutrogena.png'
          width='162'
          alt='Neutrogena logo'
          className='client-logo'
        />
      </div>
    </div>
  );
}

export default ClientsSection;
