import React from 'react';
import { Link } from 'react-router-dom';
import logoImage from '../images/p-logo-saffron_ohne_mittelkreis_512.png';
import logoImage500 from '../images/p-logo-saffron_ohne_mittelkreis_512-p-500.png';

function Header() {
  return (
    <header>
      <div className='container-2 w-container'>
        {/* <h1 className='main-heading smaller-bottom-margin align-left'>
          DSGVO
          <br />
        </h1> */}
        <div className='navigation w-nav'>
          <div className='w-container'>
            <Link to='/' className='brand-link w-nav-brand w--current'>
              <img
                src={logoImage}
                loading='lazy'
                width='50'
                sizes='50px'
                alt='PRIVAPI logo'
                srcSet={`${logoImage500} 500w, ${logoImage} 512w`}
                className='image-4'
              />
              <div className='logo-text'>
                <strong>PRIVAPI</strong>
              </div>
            </Link>
            <nav role='navigation' className='nav-menu w-nav-menu'>
              <a href='#featuresEnterprises' className='nav-link w-nav-link'>
                Für Unternehmen
              </a>
              <a href='#featuresDPO' className='nav-link w-nav-link'>
                Für Datenschutzbeauftragte
              </a>
              <a href='#pricing' className='nav-link w-nav-link'>
                Preise
              </a>
              <Link to='/blog' className='nav-link w-nav-link'>
                Blog
              </Link>
              <a
                href='https://documentation.privapi.io'
                target='_blank'
                rel='noopener noreferrer'
                className='nav-link w-nav-link'
              >
                Dokumentation
              </a>
              <a
                href='https://portal.privapi.io'
                target='_blank'
                rel='noopener noreferrer'
                className='nav-link w-nav-link'
              >
                Login
              </a>
            </nav>
            <div className='nav-link menu w-nav-button'>
              <div className='w-icon-nav-menu'></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
