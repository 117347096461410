import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/p-logo-saffron_ohne_mittelkreis_512.png';

function Impressum() {
  return (
    <div>
      <div
        data-collapse='medium'
        data-animation='default'
        data-duration='400'
        data-easing='ease'
        data-easing2='ease'
        data-no-scroll='1'
        role='banner'
        className='navigation w-nav'
      >
        <div className='w-container'>
          <Link to='/' className='brand-link w-nav-brand'>
            <img
              src={logo}
              loading='lazy'
              width='50'
              sizes='50px'
              alt=''
              className='image-4'
            />
            <div className='logo-text'>
              <strong>PRIVAPI</strong>
            </div>
          </Link>
          <nav role='navigation' className='nav-menu w-nav-menu'>
            <Link to='/#featuresEnterprises' className='nav-link w-nav-link'>
              Für Unternehmen
            </Link>
            <Link to='/#featuresDPO' className='nav-link w-nav-link'>
              Für Datenschutzbeauftragte
            </Link>
            <Link to='/#pricing' className='nav-link w-nav-link'>
              Preise
            </Link>
            <Link to='/blog' className='nav-link w-nav-link'>
              Blog
            </Link>
            <a
              href='https://documentation.privapi.io'
              target='_blank'
              rel='noopener noreferrer'
              className='nav-link w-nav-link'
            >
              Dokumentation
            </a>
            <a href='https://portal.privapi.io' className='nav-link w-nav-link'>
              Login
            </a>
          </nav>
          <div className='nav-link menu w-nav-button'>
            <div className='w-icon-nav-menu'></div>
          </div>
        </div>
      </div>
      <main id='main-content' className='main-content'>
        <div className='container-5 w-container'>
          <div>
            <h1 className='heading-7'>Impressum</h1>
          </div>
          <h3 className='heading-5'>Angaben gemäß § 5 TMG</h3>
          <p>
            PRIVAPI GmbH
            <br />
            Kastanienweg 45
            <br />
            63263 Neu-Isenburg
            <br />
            Handelsregister: HRB 54014
            <br />
            Registergericht: Amtsgericht Offenbach am Main
            <br />
            <br />
            Vertreten durch:
            <br />
            Jan Christian Ploog
          </p>
          <h3 className='heading-5'>Kontakt</h3>
          <p>
            Telefon: +49 170 7400587
            <br />
            E-Mail: info@privapi.com{' '}
          </p>
          <h3 className='heading-5'>Umsatzsteuer-ID</h3>
          <p>
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
            <br />
            DE346380221
          </p>
          <h3 className='heading-5'>EU-Streitschlichtung</h3>
          <p>
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:
            https://ec.europa.eu/consumers/odr/.
            <br />
            Unsere E-Mail-Adresse finden Sie im Impressum.
          </p>
          <h3 className='heading-5'>
            Verbraucherstreitbeilegung/Universalschlichtungsstelle
          </h3>
          <p>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </div>
      </main>
    </div>
  );
}

export default Impressum;
