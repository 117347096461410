import React from 'react';

// Überprüfen Sie, ob diese Komponenten im 'components' Ordner existieren

import MainSection from './MainSection';
import PressSection from './PressSection';
import FeaturesEnterprisesSection from './FeaturesEnterprisesSection';
import FeaturesDPOSection from './FeaturesDPOSection';
import NewsletterSection from './NewsletterSection';
import PricingSection from './PricingSection';

import ClientsSection from './ClientsSection';

function Home() {
  return (
    <>
      <MainSection />
      <PressSection />
      <FeaturesEnterprisesSection />
      {/* Entfernen Sie die doppelte PressSection */}
      <PressSection />
      <FeaturesDPOSection />
      <NewsletterSection />
      <PricingSection />
      <ClientsSection />
    </>
  );
}

export default Home;
