import React, { useEffect, useRef } from 'react';

// Importieren der Icon-Bilder
import downloadIcon from '../images/iconmonstr-download-13-icon.svg';
import ideaIcon from '../images/iconmonstr-idea-12.svg';
import smartphoneIcon from '../images/iconmonstr-smartphone-3-icon.svg';
import keyIcon from '../images/iconmonstr-key-13.svg';

// Importieren der Screenshot-Bilder
import dashboardImage from '../images/P-Dashboard.png';
import dashboardImage500 from '../images/P-Dashboard-p-500.png';
import dashboardImage800 from '../images/P-Dashboard-p-800.png';
import dashboardImage1080 from '../images/P-Dashboard-p-1080.png';
import dashboardImage1600 from '../images/P-Dashboard-p-1600.png';
import dashboardImage2000 from '../images/P-Dashboard-p-2000.png';
import dashboardImage2600 from '../images/P-Dashboard-p-2600.png';

import requestDetailsImage from '../images/P-Request-details.png';
import requestDetailsImage500 from '../images/P-Request-details-p-500.png';
import requestDetailsImage800 from '../images/P-Request-details-p-800.png';
import requestDetailsImage1080 from '../images/P-Request-details-p-1080.png';
import requestDetailsImage1600 from '../images/P-Request-details-p-1600.png';
import requestDetailsImage2000 from '../images/P-Request-details-p-2000.png';
import requestDetailsImage2600 from '../images/P-Request-details-p-2600.png';

const features = [
  {
    icon: downloadIcon,
    title: 'Multiple Eingangskanäle',
    description:
      'Integrieren Sie Betroffenenrechtsanfragen direkt in Ihre Webseite, mobile App und Desktop-Anwendung oder empfangen Sie Anfragen traditionell via E-Mail oder Telefon.',
  },
  {
    icon: ideaIcon,
    title: 'Smarte Bearbeitung von Anfragen',
    description:
      'Verringern Sie den Bearbeitungsaufwand durch umfangreiche Automatisierungsoptionen und mit Vorlagen / Muster für die Beantwortung von Anfragen.',
  },
  {
    icon: smartphoneIcon,
    title: 'Identifizierung',
    description:
      'Identifizieren Sie Anfragende automatisch und stellen Sie die Legitimität der Anfrage fest. Oder starten Sie auf Knopfdruck den unterstützten Identifizierungsprozess.',
  },
  {
    icon: keyIcon,
    title: 'Verschlüsselter Informationsaustausch',
    description:
      'Tauschen Sie Informationen mit Anfragenden über einen im Hintergrund etablierten, verschlüsselten Kanal aus. Vermeiden Sie Risiken aus dem traditionellen Versand von Daten.',
  },
];

function FeatureItem({ icon, title, description }) {
  return (
    <div className='main-feature-group'>
      <img src={icon} height='30' alt={title} className='feature-icon' />
      <h3>
        <span className='color-blue'>{title}</span>
      </h3>
      <p className='paragraph-3'>{description}</p>
    </div>
  );
}

function FeaturesEnterprisesSection() {
  const imgRef1 = useRef();
  const imgRef2 = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform =
              'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)';
          }
        });
      },
      { threshold: 0.1 }
    );

    if (imgRef1.current) observer.observe(imgRef1.current);
    if (imgRef2.current) observer.observe(imgRef2.current);

    return () => {
      if (imgRef1.current) observer.unobserve(imgRef1.current);
      if (imgRef2.current) observer.unobserve(imgRef2.current);
    };
  }, []);

  return (
    <div id='featuresEnterprises' className='section'>
      <div className='container-11 w-container'>
        <h2 className='heading-2'>Für Unternehmen</h2>
        <div className='section-subtitle'>
          <br />
          Lösung zur automatisierten Bearbeitung von Betroffenenrechtsanfragen
          nach DSGVO
        </div>
        <div className='feature-row w-row'>
          {features.map((feature, index) => (
            <div
              key={index}
              className={`${index % 2 === 0 ? 'column-5 ' : ''}w-col w-col-6`}
            >
              <FeatureItem {...feature} />
            </div>
          ))}
        </div>
        <div className='image-row w-row'>
          <div className='w-col w-col-6'>
            <img
              ref={imgRef1}
              src={dashboardImage}
              alt='PRIVAPI PORTAL Dashboard Screenshot'
              style={{
                transform:
                  'translate3d(0, 25PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                opacity: 0,
                transition: 'opacity 0.5s, transform 0.5s',
              }}
              sizes='(max-width: 767px) 96vw, (max-width: 991px) 354px, 460px'
              srcSet={`${dashboardImage500} 500w, ${dashboardImage800} 800w, ${dashboardImage1080} 1080w, ${dashboardImage1600} 1600w, ${dashboardImage2000} 2000w, ${dashboardImage2600} 2600w, ${dashboardImage} 3002w`}
            />
          </div>
          <div className='w-col w-col-6'>
            <img
              ref={imgRef2}
              src={requestDetailsImage}
              alt='PRIVAPI PORTAL Request Details Screenshot'
              style={{
                transform:
                  'translate3d(0, 25PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                opacity: 0,
                transition: 'opacity 0.5s, transform 0.5s',
              }}
              sizes='(max-width: 767px) 96vw, (max-width: 991px) 354px, 460px'
              srcSet={`${requestDetailsImage500} 500w, ${requestDetailsImage800} 800w, ${requestDetailsImage1080} 1080w, ${requestDetailsImage1600} 1600w, ${requestDetailsImage2000} 2000w, ${requestDetailsImage2600} 2600w, ${requestDetailsImage} 3002w`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturesEnterprisesSection;
