import React from 'react';

function NewsletterSection() {
  return (
    <div className='section-3'>
      <div className='container-6 w-container'>
        <div className='form-block w-form'>
          <h2 className='heading-2'>Anmelden zum Newsletter</h2>
          <div className='section-subtitle'>
            <strong>‍</strong>
            <br />
            Melden Sie Sich jetzt zum Newsletter an, um über den Start des
            PRIVAPI PORTAL, über Rabatte zum Start und über Neuigkeiten
            informiert zu werden.
          </div>
          <form
            id='email-form'
            name='email-form'
            data-name='Email Form'
            action='https://seu2.cleverreach.com/f/320403-324725/wcs/'
            method='post'
            target='_blank'
            className='form'
          >
            <div className='columns-2 w-row'>
              <div className='column-4 w-col w-col-3'></div>
              <div className='column-3 w-col w-col-6'>
                <input
                  className='text-field w-input'
                  maxLength='256'
                  name='email'
                  data-name='email'
                  placeholder='E-Mail Adresse'
                  type='email'
                  id='email'
                  required=''
                />
                <label htmlFor='email-2' className='field-label-2'>
                  Ja, ich möchte mich zum Newsletter der PRIVAPI GmbH anmelden
                  und regelmäßig über fachliche Informationen zum Datenschutz,
                  Neuigkeiten zu Angeboten der PRIVAPI GmbH sowie zu
                  interessanten Produkten auch anderer Unternehmen im
                  Zusammenhang mit Datenschutz informiert werden. Sie können
                  Sich jederzeit vom Newsletter abmelden. <br />
                  <br />
                  Hinweise zum Widerruf und zur Verarbeitung Ihrer Daten finden
                  Sie in unserer{' '}
                  <a href='datenschutz.html' className='page-link'>
                    Datenschutzerklärung
                  </a>
                  .
                </label>
                <input
                  type='submit'
                  data-wait='Please wait...'
                  className='button button-newsletter w-button'
                  value='Anmelden'
                />
              </div>
              <div className='w-col w-col-3'></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewsletterSection;
