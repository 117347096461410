import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  const handleClick = (to) => {
    navigate(to);
    window.scrollTo(0, 0);
  };

  return (
    <div className='section footer'>
      <div className='w-container'>
        <div className='w-row'>
          <div className='w-col w-col-4'>
            <div className='logo-text footer'>PRIVAPI</div>
          </div>
          <div className='w-col w-col-3'>
            <h4 className='footer-title'>Menü</h4>
            <Link
              to='/#featuresEnterprises'
              className='page-link in-footer'
              onClick={() => handleClick('/#featuresEnterprises')}
            >
              Für Unternehmen
            </Link>
            <Link
              to='/#featuresDPO'
              className='page-link in-footer'
              onClick={() => handleClick('/#featuresDPO')}
            >
              Für Datenschutzbeauftragte
            </Link>
            <Link
              to='/#pricing'
              className='page-link in-footer'
              onClick={() => handleClick('/#pricing')}
            >
              Preise
            </Link>
            <a
              href='https://documentation.privapi.io'
              className='page-link in-footer'
            >
              Dokumentation
            </a>
            <Link
              to='/blog'
              className='page-link in-footer'
              onClick={() => handleClick('/blog')}
            >
              Blog
            </Link>
          </div>
          <div className='w-col w-col-3'>
            <h4 className='footer-title'>Kontakt</h4>
            <a
              href='mailto:info@privapi.io?subject=Nachricht%20eines%20Nutzers%20der%20PRIVAPI%20Webseite'
              className='page-link in-footer'
            >
              PRIVAPI GmbH
              <br />
              info@privapi.io
              <br />
            </a>
          </div>
          <div className='w-col w-col-2'>
            <h4 className='footer-title'>Rechtliches</h4>
            <Link
              to='/impressum'
              className='page-link in-footer'
              onClick={() => handleClick('/impressum')}
            >
              Impressum
            </Link>
            <Link
              to='/datenschutz'
              className='page-link in-footer'
              onClick={() => handleClick('/datenschutz')}
            >
              Datenschutz
            </Link>
            <Link
              to='/terms'
              className='page-link in-footer'
              onClick={() => handleClick('/terms')}
            >
              AGB
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
