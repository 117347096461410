import React from 'react';
import sitemapIcon from '../images/iconmonstr-sitemap-7-icon.svg';
import tagIcon from '../images/iconmonstr-tag-13.svg';
import shieldIcon from '../images/iconmonstr-shield-20.svg';

function FeaturesDPOSection() {
  return (
    <div id='featuresDPO' className='section'>
      <div className='container-10 w-container'>
        <h2 className='heading-2'>Für Datenschutzbeauftragte</h2>
        <h3 className='heading-23'>
          Kostenlos für externe Datenschutzbeauftragte
        </h3>
        <div className='section-subtitle'>
          <strong>‍</strong>
          <br />
          Datenschutzbeauftragte mit mehreren Mandanten können das PRIVAPI
          PORTAL ihren Mandanten mit eigenem Branding anbieten. Über ein
          einziges Portal behalten ext. Datenschutzbeauftragte einen Überblick
          über eintreffende Betroffenenrechtsanfragen, Fristen und
          Bearbeitungsstände bei ihren Mandanten. <br />
          <br />
          Bieten sie Ihren Mandanten zusätzliche Dienste, wie z.B. die
          Überprüfung und Freigabe von Antworten, die von Mandanten verfasst
          wurden.
        </div>
        <div className='small-features-row only-3-items w-row'>
          <div className='w-col w-col-4'>
            <div className='feature'>
              <img
                src={sitemapIcon}
                height='30'
                alt='Sitemap Icon'
                className='feature-icon'
              />
              <h3 className='heading-13'>
                <span className='color-blue'>Alle Mandanten im Überblick</span>
              </h3>
              <p className='body-text-feature-row'>
                Sehen Sie alle Anfragen an Ihre Mandanten an einem Ort und
                werden an auslaufende Fristen erinnert.
              </p>
            </div>
          </div>
          <div className='w-col w-col-4'>
            <div className='feature'>
              <img
                src={tagIcon}
                height='30'
                alt='Tag Icon'
                className='feature-icon'
              />
              <h3 className='heading-13'>
                <span className='color-blue'>Anfragen bearbeiten</span>
              </h3>
              <p className='body-text-feature-row'>
                Greifen Sie in die Bearbeitung der Anfragen ein, oder prüfen Sie
                Anfragen vor Versand an den Betroffenen.
              </p>
            </div>
          </div>
          <div className='w-col w-col-4'>
            <div className='feature'>
              <img
                src={shieldIcon}
                height='30'
                alt='Shield Icon'
                className='feature-icon'
              />
              <h3 className='heading-13'>
                <span className='color-blue'>Zusätzliche Dienste</span>
              </h3>
              <p className='body-text-feature-row'>
                Bieten Sie zusätzliche, diskretionäre Dienstleistungen an (z.B.
                Review von einzelnen Anfragen).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturesDPOSection;
