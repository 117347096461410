import React from 'react';

function PricingSection() {
  return (
    <div id='pricing' className='section purple'>
      <div className='container w-container'>
        <h2 className='heading-2'>Preise für Unternehmen</h2>
        <div className='section-subtitle'>
          <strong className='bold-text'>30 Tage kostenlos testen</strong>
        </div>
        <div className='columns-3 w-row'>
          <div className='w-col w-col-4'>
            <div
              data-w-id='49139085-6cc2-1d4a-786f-9102d354fcb0'
              className='plan-wrapper'
            >
              <h3>Grundgebühr</h3>
              <div className='price-point'>
                <strong className='price-accent'>€ 199</strong> /Monat
              </div>
              <div className='price-point'>
                <strong className='price-accent'>1</strong> Benutzer
                <br />
              </div>
              <div className='divider'></div>
              <div className='addl-feature featurenotavailable'>
                Erfassung von Anfragen aus E-Mails
              </div>
              <div className='addl-feature'>
                Manuelle Erfassung von Anfragen
              </div>
              <div className='addl-feature featurenotavailable'>
                Unterstützte Identifizierung von Anfragenden
              </div>
              <div className='addl-feature featurenotavailable'>
                Smart Responses
              </div>
              <div className='addl-feature featurenotavailable'>
                Automatisch verschlüsselte Kommunikation mit Anfragenden
              </div>
              <div className='addl-feature'>
                Übersicht und Fristenüberwachung
                <br />‍
              </div>
              <a
                href='/signup#starter'
                className='button in-pricing basefeebutton'
              >
                Ausprobieren
              </a>
            </div>
          </div>
          <div className='column-2 w-col w-col-4'>
            <div
              data-w-id='4ab498f8-b835-cba0-5a7c-16bed40aae2a'
              className='plan-wrapper'
            >
              <h3>Zusätzliche Nutzer</h3>
              <div className='price-point'>
                <strong className='price-accent'>€ 39</strong> /Monat pro Nutzer
              </div>
              <div className='divider'></div>
              <div className='addl-feature'>
                Mit mehreren Nutzern können Sie Rollen festlegen und Aufgaben
                delegieren.
                <br />
                <br />
                Z.B. die Bearbeitung einer Anfrage im Customer Service, die
                Zulieferung von Daten durch IT-Ops sowie Prüfung, Freigabe und
                Versand von Antworten durch den Datenschutzbeauftragten des
                Unternehmens.
              </div>
              <a href='/signup#starter' className='button in-pricing'>
                Ausprobieren
              </a>
            </div>
          </div>
          <div className='w-col w-col-4'>
            <div
              data-w-id='36a69626-52ca-b009-2f12-75a01dceb9e4'
              className='plan-wrapper'
            >
              <h3>Direkte Integrationen</h3>
              <div className='price-point'>
                <strong className='price-accent'>€ 39</strong> /Monat pro
                Integration
              </div>
              <div className='divider'></div>
              <div className='addl-feature'>
                Integrieren Sie Ihre digitalen Angebote, von denen Ihre
                registrierten oder nicht-registrierten Kunden aus Anfragen
                senden können. Z.B. von Webseiten oder mobilen Apps. <br />
                <br />
                Sind Ihre Kunden innerhalb dieser digitalen Angebote bereits
                ausreichend authentifiziert, kann bspw. der Schritt der
                Identifizierung eines Betroffenen vor der Bearbeitung der
                Anfrage entfallen.
                <br />
                <br />
                <br />‍
              </div>
              <a href='/signup#starter' className='button in-pricing'>
                Ausprobieren
              </a>
            </div>
          </div>
        </div>
        <div className='additional-contact subtext'>
          <strong className='bold-text subtext'>
            Alle Preise zzgl. gesetzlicher Umsatzsteuer. <br />‍<br />
            Fair-Use-Policy: Der Erhalt und die Bearbeitung von Anfragen ist für
            bis zu 100 Stück im Monat vorgesehen. <br />
            Sollten Ihr Unternehmen regelmäßig mehr Betroffenenrechtsanfragen
            bearbeiten, <br />‍
          </strong>
          <a
            href='mailto:support@privapi.io?subject=Anfrage%20von%20Webseite'
            className='page-link'
          >
            kontaktieren Sie uns
          </a>{' '}
          gerne für ein individuelles Angebot.
        </div>
      </div>
      <div className='container w-container'>
        <h3 className='heading-23 highlight'>
          Kostenlos für externe Datenschutzbeauftragte
        </h3>
        <div className='text-block-6'>
          Voraussetzungen für die Eröffnung eines kostenlosen Kontos für externe
          Datenschutzbeauftragte sind eine gewerbliche oder freiberufliche
          Tätigkeit, also kein Anstellungsverhältnis mit einem der mit dem
          PRIVAPI PORTAL verwalteten Mandanten und die Tätigkeit des externen
          Datenschutzbeauftragten für mehr als nur einen Mandanten.
        </div>
        <a
          href='signup-dpo.html'
          className='button framed-transparent w-button'
        >
          Anmelden als Datenschutzbeauftragter
        </a>
      </div>
    </div>
  );
}

export default PricingSection;
