import React from 'react';

const pressLogos = [
  {
    src: '/images/logo_Fastcompany.svg',
    alt: 'Fast Company logo',
    width: '70.5',
  },
  { src: '/images/logo_Forbes.svg', alt: 'Forbes logo', width: '70' },
  { src: '/images/logo_techcrunch.svg', alt: 'TechCrunch logo', width: '70' },
  { src: '/images/logo_Wired.svg', alt: 'Wired logo', width: '70' },
  { src: '/images/logo_zdnet.svg', alt: 'ZDNet logo', width: '70' },
];

function PressSection() {
  return (
    <div className='section press'>
      <div className='w-container'>
        <div className='small-text'>IN THE PRESS</div>
        <div className='div-block'>
          {pressLogos.map((logo, index) => (
            <img
              key={index}
              src={logo.src}
              width={logo.width}
              alt={logo.alt}
              className='logo'
              style={{
                transition: 'transform 0.3s ease-in-out',
                cursor: 'pointer',
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = 'scale(1.1)';
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = 'scale(1)';
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PressSection;
